<template>
  <ui-component-modal
    modalTitle="Edit option price"
    modalSize="large"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="saveOptionPrices"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <table class="table is-fullwidth is-striped">
        <thead>
          <tr>
            <th>Valid from</th>
            <th class="has-text-right">Max. p.p.</th>
            <th class="has-text-right">Max. total</th>
            <th class="has-text-right">Price type</th>
            <th class="has-text-right">VAT</th>
            <th class="has-text-right">Price</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(price, index) in channelPrices" :key="index">
            <td>
              <ui-base-date-field
                label=""
                dataFormat="shortDateFormat"
                v-model="price.ValidFrom"
                :openCalendarInModal="true"
              />

              <!-- <v-date-picker
                mode="single"
                v-model="price.ValidFrom"
                :value="formatDates(price.ValidFrom)"
              >
                <input
                  type="text"
                  class="input"
                  :id="'datepicker-trigger'"
                  readonly
                  :value="formatDates(price.ValidFrom)"
                />
              </v-date-picker> -->
            </td>
            <td>
              <input type="text" class="input" v-model.number="price.MaxPP" />
            </td>
            <td>
              <input
                type="text"
                class="input"
                v-model.number="price.MaxTotal"
              />
            </td>
            <td>
              <div class="select">
                <select v-model="price.PriceType">
                  <option
                    v-for="(type, index) in calculationTypes"
                    :key="index"
                    :value="type.Type"
                    :selected="type.Type === price.PriceType"
                  >
                    {{ type.Name }}
                  </option>
                </select>
              </div>
            </td>
            <td>
              <div v-if="locationId === 0 || taxPercentages.length === 0">
                <input
                  type="number"
                  v-model="price.TaxPercentage"
                  class="input"
                />
              </div>

              <div
                v-if="locationId > 0 && taxPercentages.length > 0"
                class="select"
              >
                <select v-model="price.TaxPercentage">
                  <option
                    v-for="taxPercentage in taxPercentages"
                    :key="taxPercentage"
                    :value="taxPercentage"
                    :selected="taxPercentage === price.TaxPercentage"
                  >
                    {{ taxPercentage }}%
                  </option>
                </select>
              </div>
            </td>
            <td>
              <input type="text" class="input" v-model.number="price.Price" />
            </td>
            <td>
              <a @click="deletePrice(price)">
                <span class="icon is-small has-text-danger">
                  <font-awesome-icon :icon="['fas', 'trash-alt']" />
                </span>
              </a>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="7">
              <a @click="addPrice" class="button is-success">Add</a>
            </td>
          </tr>
        </tfoot>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import optionProvider from '@/providers/option'
import format from 'date-fns/format'
import VCalendar from 'v-calendar'
Vue.use(VCalendar, {})

export default {
  components: {},

  props: {
    channelId: {
      type: Number,
      required: 0,
    },

    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      calculationTypes: [],
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      locationId: this.$route.params.locationId
        ? Number(this.$route.params.locationId)
        : 0,
      mOption: null,
      newOptionPrice: null,
      dataFormat: 'dd MMMM yyyy',
      dateOne: new Date(),
      dateTwo: new Date(),
    }
  },

  computed: {
    ...mapState('locationStore', ['locationState']),
    ...mapState('optionStore', ['option']),

    channels() {
      if (this.locationState) {
        return this.locationState.Channels
      } else {
        return []
      }
    },

    channelPrices() {
      for (let i = 0; i < this.mOption.Prices.length; i++) {
        let price = this.mOption.Prices[i]
        price.index = i
      }

      return this.mOption.Prices.filter((p) => p.ChannelId === this.channelId)
    },

    taxPercentages() {
      let result = []

      if (this.locationState) {
        result = this.locationState.TaxPercentages.map((t) => t.TaxPercentage)
      }

      return result
    },
  },

  created() {
    this.mOption = JSON.parse(JSON.stringify(this.option))

    this.calculationTypes.push({ Id: 0, Type: 'PerItem', Name: 'Per item' })
    this.calculationTypes.push({ Id: 1, Type: 'PerPerson', Name: 'Per person' })
    this.calculationTypes.push({
      Id: 2,
      Type: 'PerDayPart',
      Name: 'Per day part',
    })
    this.calculationTypes.push({
      Id: 4,
      Type: 'PerPersonPerDayPart',
      Name: 'Per person per day part',
    })

    this.newOptionPrice = {
      OptionId: this.option.Id,
      ChannelId: this.channelId,
      ValidFrom: new Date(),
      MaxPP: 0,
      MaxTotal: 0,
      PriceType: 'PerItem',
      TaxId: 0,
      TaxPercentage: 0,
      CurrencyId: this.locationState
        ? this.locationState.Settings.CurrencyId
        : 46,
      Price: 0,
    }
  },

  methods: {
    ...mapMutations('optionStore', ['setOption']),

    formatDates(dateOne = '', dateTwo = '') {
      let formattedDates = ''
      if (dateOne) {
        formattedDates = format(dateOne, this.dataFormat)
      }
      return formattedDates
    },

    addPrice() {
      this.mOption.Prices.push(this.newOptionPrice)

      this.newOptionPrice = {
        OptionId: this.option.Id,
        ChannelId: this.channelId,
        LocationId: this.locationId,
        ValidFrom: new Date(),
        MaxPP: 0,
        MaxTotal: 0,
        PriceType: 'PerItem',
        TaxId: 0,
        TaxPercentage: 0,
        CurrencyId: this.locationState
          ? this.locationState.Settings.CurrencyId
          : 46,
        Price: 0,
      }
    },

    deletePrice(price) {
      this.mOption.Prices.splice(price.index, 1)
    },

    saveOptionPrices() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        for (let i = 0; i < self.mOption.Prices.length; i++) {
          self.mOption.Prices[i].ValidFrom =
            self.$options.filters.dateObjectIsoDateString(
              self.mOption.Prices[i].ValidFrom
            )
        }

        optionProvider.methods
          .saveLocationOptionPrices(
            self.mOption.Id,
            self.locationId,
            self.mOption.Prices
          )
          .then((response) => {
            self.isSavingSuccess = true

            self.setOption(response.data)

            self.onClickCancel()
          })
          .catch((e) => {
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },
  },
}
</script>
